import Header from './Header.js';

export default function PrivacyPolicy() {
	return(
		<>
			<Header />
			<div className="bg-[#f8f8f8] py-[50px] px-[20px] sm:px-[50px] font-['Lexend']">
				<div className="max-w-[1000px] bg-white mx-auto shadow-xl rounded-[10px] py-[20px] px-[20px] sm:px-[30px] font-normal text-[#4e4e4e] leading-[30px]">
					<div className="text-[24px] sm:text-[30px] font-semibold mb-[10px] text-black">
					    Privacy Policy
					</div>
					<p>
					    Your privacy is a top priority for us. To safeguard your personal information and maintain confidentiality, we have established this Privacy Policy. While we strive to comply with international privacy laws, we cannot guarantee adherence to all foreign regulations. We take reasonable measures to protect your privacy on our controlled systems and website, but we are not responsible for unauthorized or unlawful disclosures of your personal information by third parties beyond our control, such as advertisers and external websites linked from our platform. Please be aware that the privacy practices of our business partners, advertisers, sponsors, or external sites we link to may differ from ours. This Privacy Policy is subject to updates at any time without prior notice. To stay informed about any changes, we encourage you to review this policy periodically.
					</p>
					<p className="mt-[10px]">
						We categorise information about you (collectively referred to as “Personal Information”) as follows:
					</p>
					<p className="font-semibold text-black mt-[10px] text-[18px]">
						1. Profiling Information:
					</p>
					<p>
					    When you register for a Service, you may be required to provide certain information, which may include but not limited to the following:  
					    <ul className="list-disc pl-[30px] sm:pl-[50px]">
						    <li>Personal identity details such as gender, marital status, age, preferences, interests, and dislikes.</li>  
							<li>Financial information, including banking details.</li>  
							<li>Contact details such as residential addresses, postal addresses, phone numbers, and fax numbers.</li>  
							<li>For Facebook-linked services (if opted by you), publicly available details of your friends, including their interests and preferences.</li>
						</ul>
					</p>
					<p className="font-semibold text-black mt-[10px] text-[18px]">
						2. Payment and Account Information:
					</p>
					<p>
					    We securely store your account history, which includes but is not limited to billing details, communication records, and payment transactions. This data is maintained in an encrypted format on secure servers to ensure its confidentiality and protection.
					</p>
					<p className="font-semibold text-black mt-[10px] text-[18px]">
						3. Service Usage:
					</p>
					<p>
					    We collect data regarding your browsing activities while using our Services, including the URLs of websites you visit and any download requests you make. This information helps us enhance user experience and service functionality.
					</p>
					<p className="font-semibold text-black mt-[10px] text-[18px]">
						4. Log information:
					</p>
					<p>
					    We gather details such as your web requests, IP address, browser type and language, as well as the date and time of your request. This data helps us improve our services and ensure a secure user experience.
					</p>
					<p className="font-semibold text-black mt-[10px] text-[18px]">
						5. Transactional Information:
					</p>
					<p>
					    We collect records of your transactional history, excluding banking details, related to your e-commerce activities. This information helps us enhance our services and provide a better user experience.
					</p>

					<p className="font-semibold text-black mt-[10px] text-[18px]">
						6. Correspondence Information:
					</p>
					<p>
					    We gather details about your interactions, including the content and recipients of communications made through our Services. This encompasses emails, blog discussions, chat room interactions, discussion boards, instant messaging, expert forums, faxmail exchanges, and participation in mailing lists.
					</p>

					<p className="font-semibold text-black mt-[10px] text-[18px]">
						7. User IDs:
					</p>
					<p>
					    We collect and store your login credentials, including usernames, passwords, email addresses, and other security-related details necessary for accessing and utilizing our Services.
					</p>

					<p className="font-semibold text-black mt-[10px] text-[18px]">
						8. Stored Information:
					</p>
					<p>
					    We store data generated by you or shared by third parties, including images, documents, and other files you choose to save on our servers. Our collection of Personal Information is solely intended to facilitate and enhance our Services. Under no circumstances do we sell or commercially trade your Personal Information with third parties.
					</p>
					<p className="mt-[10px]">
					    Your Personal Information will only be disclosed as outlined in this Privacy Policy. Should we intend to use it for any other purpose, we will seek your explicit written consent. If you choose not to provide your personal information, we may be unable to offer you our Services. 

					    Any information you share in public forums such as blogs, community discussions, or message boards is not confidential, does not qualify as Personal Information, and is not protected under this Privacy Policy. Since these forums are accessible to third parties, they may collect, compile, and use such information for their own purposes. Therefore, exercise caution before sharing personal details in public spaces. 

					    Publicly disclosed information may also be shared with our affiliates, third-party service providers, and competition sponsors unless explicitly stated otherwise. We hold no responsibility for any damages incurred by you or any third party due to your voluntary disclosure of Personal Information in a public setting. Sharing such information in a public space is entirely at your own risk.
					</p>

					
					<div className="text-[24px] sm:text-[30px] font-semibold mb-[5px] mt-[30px] text-black">
						General Exceptions
					</div>
					<p>
					    If required by law, to operate our business, to protect our systems, or to enforce our rights, we may intercept, disclose, monitor, and/or store your Personal Information in accordance with legal provisions.
					</p>
					<p className="mt-[10px]">
					    This interception, disclosure, monitoring, and storage may occur without prior notification to you. In such cases, we shall not be held responsible for any loss or damages incurred by you or any third party as a result of these actions.
					</p>
					<p className="mt-[10px]">
					    To ensure compliance with our Terms of Service, we may monitor Personal Information when necessary to identify violations. Additionally, to maintain the security and integrity of our Services, we may filter electronic data, including incoming and outgoing messages, to prevent spam, malware, or inappropriate content. In instances where certain Services are outsourced to third-party providers, we may need to share limited Personal Information with them solely for the purpose of facilitating those Services.
					</p>
				</div>
			</div>
		</>
	)
}